import React, { useState, useEffect } from "react";
import moment from "moment";
import HasOffersAPI from "../../services/HasOffersAPI";
import { PageHeader, Spin, List, Row } from "antd";
import { format_number_commas } from "../../util";
import { reportToSentry } from "../../util";
import { ExclamationOutlined } from "@ant-design/icons";
const Today = moment().format("YYYY-MM-DD");
const MonthName = moment().startOf("month").format("MMMM");
const StartOfMonth = moment().startOf("month").format("YYYY-MM-DD");
const StartOfYear = moment().startOf("year").format("YYYY-MM-DD");
const StatCard = ({ title, loading, data }) => (
  <List.Item>
    <List.Item.Meta title={title} />
    <div className="list_amount">
      {loading ? (
        <Spin size="small" />
      ) : data && data === "null" ? (
        <ExclamationOutlined style={{ color: "#800121" }} />
      ) : (
        `${
          title !== "Clicks YTD" ? (title !== "Conversions YTD" ? "$" : "") : ""
        }${format_number_commas(data)}`
      )}
    </div>
  </List.Item>
);
const AccountTotals = ({ auth }) => {
  const Report = HasOffersAPI.report;
  const [totalYTD, setTotalYTD] = useState(0);
  const [clicksYTD, setClicksYTD] = useState(0);
  const [conversionsYTD, setConversionsYTD] = useState(0);
  const [monthTotals, setMonthTotals] = useState(0);
  const [loadingTotal, setLoadingTotal] = useState(true);
  const [loadingConversions, setLoadingConversions] = useState(true);
  const [loadingMonthTotals, setLoadingMonthTotals] = useState(true);
  const [loadingClicks, setLoadingClicks] = useState(true);

  const fetchStats = async (query) => {
    return new Promise((resolve, reject) => {
      Report.getStats(query, true)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  };
  const grabStats = async (query, dates, filter) => {
    try {
      const api = await fetchStats({
        [`fields`]: ["Stat.payout", "Stat.conversions", "Stat.clicks"],
        [`filters[Stat.affiliate_manager_id][conditional]`]: "EQUAL_TO",
        [`filters[Stat.affiliate_manager_id][values]`]: 72,
        [`filters[Stat.date][conditional]`]: "BETWEEN",
        totals: 1,
        ...query,
      });
      let data = api.data;
      return data[0];
    } catch (error) {
      reportToSentry([
        "performance_chart",
        `Error fetching account totals data`,
        "HasOffersAPI",
      ]);
      return {
        Stat: {
          conversions: "null",
          clicks: "null",
          payout: "null",
        },
      };
    }
  };
  useEffect(() => {
    const stats = async () => {
      try {
        let totalYTD = await grabStats({
          [`filters[Stat.date][values][]`]: [StartOfYear, Today],
        });
        setLoadingTotal(false);
        setLoadingClicks(false);
        setLoadingConversions(false);
        let { clicks, payout, conversions } = totalYTD.Stat;
        setTotalYTD(payout == "null" ? payout : parseInt(payout).toFixed(2));
        setClicksYTD(clicks === "null" ? clicks : parseInt(clicks));
        setConversionsYTD(
          conversions === "null" ? conversions : parseInt(conversions)
        );
        let totalMonth = await grabStats({
          [`filters[Stat.date][values][]`]: [StartOfMonth, Today],
        });
        setLoadingMonthTotals(false);
        setMonthTotals(
          totalMonth.Stat.payout === "null"
            ? totalMonth.Stat.payout
            : parseInt(totalMonth.Stat.payout).toFixed(2)
        );
      } catch (error) {
        reportToSentry([
          "performance_chart",
          `Error setting account totals data`,
          "HasOffersAPI",
        ]);
      }
    };
    stats();
  }, []);
  const stat_list = [
    {
      title: "Earnings YTD",
      loading: loadingTotal,
      data: totalYTD,
    },
    {
      title: "Clicks YTD",
      loading: loadingClicks,
      data: clicksYTD,
    },
    {
      title: "Conversions YTD",
      loading: loadingConversions,
      data: conversionsYTD,
    },
    {
      title: `${MonthName}`,
      loading: loadingMonthTotals,
      data: monthTotals,
    },
  ];
  return (
    <PageHeader
      className="main_page_header"
      style={{ width: "100%" }}
      title={`Account Totals `}
    >
      <Row>
        <List
          className="account_total_list"
          size="small"
          style={{ width: "100%" }}
          bordered={false}
          dataSource={stat_list}
          renderItem={(item) => (
            <StatCard
              title={item.title}
              loading={item.loading}
              data={item.data}
            />
          )}
        />
      </Row>
    </PageHeader>
  );
};
export default AccountTotals;
