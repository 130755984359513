import React from "react";
import { Card } from "antd";
import { Result, Col, Row, Spin } from "antd";
import { format_number_commas } from "../../util";
import { formatCurrency } from "../../util";
import { Line } from "react-chartjs-2";
import millify from "millify";
import { tooltip_afterTitle } from "../../helpers/Charts";

const cardStyle = { textAlign: "center" };

const PerformanceChart = ({
  initialLoading,
  chartData,
  currentData,
  currentTotals,
  PandaIcon,
  byHours,
}) => {
  return (
    <Spin spinning={initialLoading}>
      {currentData === null ? (
        <Result
          icon={<PandaIcon style={{ fontSize: "32px" }} />}
          title="No Results Found"
          subTitle="Please select a different date"
        />
      ) : (
        <>
          <Row gutter={[8, 8]}>
            {currentTotals &&
              Object.entries(currentTotals).map((val, key) => (
                <Col key={key} span={8}>
                  <Card
                    bordered={false}
                    size="small"
                    title={val[0].toUpperCase()}
                    style={cardStyle}
                    className="performance_card"
                  >
                    {val[0] === "clicks" &&
                      format_number_commas(currentTotals.clicks)}
                    {val[0] === "conversions" &&
                      format_number_commas(currentTotals.conversions)}
                    {val[0] === "payout" &&
                      formatCurrency(currentTotals.payout)}
                  </Card>
                </Col>
              ))}
          </Row>
          <Line
            options={{
              tooltips: {
                backgroundColor: "#2b71ae",
                callbacks: {
                  title: (tooltipItem, data) =>
                    data.datasets[tooltipItem[0].datasetIndex].label || "",
                  afterTitle: (tooltipItem, data) => {
                    return tooltip_afterTitle(tooltipItem, data);
                  },
                  labelColor: (tooltipItem, chart) => {
                    let color =
                      chart.config.data.datasets[tooltipItem.datasetIndex]
                        .backgroundColor;
                    return {
                      borderColor: color,
                      backgroundColor: color,
                    };
                  },
                  label: (tooltipItem, data) => {
                    let label = ` ${formatCurrency(
                      Math.round(tooltipItem.yLabel * 100) / 100
                    )}`;
                    return label;
                  },
                },
              },
              animation: {
                duration: 500,
              },
              scales: {
                yAxes: [
                  {
                    ticks: {
                      callback: (value) => "$" + millify(value),
                      autoSkip: true,
                      maxTicksLimit: 5,
                      fontStyle: "normal",
                    },
                    gridLines: {
                      display: false,
                    },
                  },
                ],
                xAxes: [
                  {
                    ticks: {
                      autoSkip: true,
                      maxTicksLimit: byHours ? 11 : 50,
                      fontSize: 12,
                    },
                    gridLines: {
                      display: false,
                    },
                  },
                ],
              },
            }}
            data={chartData}
          />
        </>
      )}
    </Spin>
  );
};

export default PerformanceChart;
