import React, { useState, useEffect } from "react";
import * as Sentry from "@sentry/browser";
import { PageHeader, Spin, Card, Col, Row } from "antd";
import { Link } from "react-router-dom";
import { format_number_commas } from "../../util";
import HasOffersAPI from "../../services/HasOffersAPI";
const cardStyle = {
  textAlign: "center",
};
const TotalAffiliates = ({ auth }) => {
  const [totalActive, setTotalActive] = useState(0);
  const [loadingActive, setLoadingActive] = useState(true);
  const [totalPending, setTotalPending] = useState(true);
  const [loadingPending, setLoadingPending] = useState(true);
  const Affiliate = HasOffersAPI.affiliate;

  const fetchAffiliates = async (query) => {
    return new Promise((resolve, reject) => {
      Affiliate.findAffiliates(query, true)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  };
  useEffect(() => {
    const fetchStats = async () => {
      try {
        let totalActive = await fetchAffiliates({
          [`filters[account_manager_id]`]: 72,
          limit: 1,
        });
        setLoadingActive(false);
        setTotalActive(totalActive.count);
        let totalPending = await fetchAffiliates({
          [`filters[status]`]: "pending",
          [`contain[]`]: "AffiliateUser",
          limit: 1,
        });
        setLoadingPending(false);
        setTotalPending(totalPending.count);
      } catch (error) {
        Sentry.captureException(error);
      }
    };
    fetchStats();
  }, []);

  return (
    <PageHeader
      className="main_page_header"
      style={{
        width: "100%",
      }}
      title={`Affiliates`}
    >
      <Row gutter={[8, 8]}>
        <Col span={12}>
          <Card
            bordered={false}
            className="performance_card"
            style={cardStyle}
            size="small"
            title={<Link to="/affiliates/manage">Active</Link>}
          >
            {loadingActive ? (
              <Spin size="small" />
            ) : (
              totalActive && format_number_commas(totalActive)
            )}
          </Card>
        </Col>
        <Col span={12}>
          <Card
            bordered={false}
            className="performance_card"
            style={cardStyle}
            size="small"
            title={<Link to="/affiliates/pending">Pending</Link>}
          >
            {loadingPending ? (
              <Spin size="small" />
            ) : (
              totalPending && format_number_commas(totalPending)
            )}
          </Card>
        </Col>
      </Row>
    </PageHeader>
  );
};
export default TotalAffiliates;
