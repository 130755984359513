import React, { useState, useEffect } from "react";
import { Layout, Menu, Breadcrumb } from "antd";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { Link } from "react-router-dom";
import * as Firestore from "../../services/Firebase";
import "./dashboardContainer.scss";
import Logo from "../../assets/tik-tunes-logo.png";
import { authAction } from "../../state/actions/AuthAction";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Backend from "../../services/Backend";
import {
  UserOutlined,
  LogoutOutlined,
  UserAddOutlined,
  HomeFilled,
  EditOutlined,
  AreaChartOutlined,
  TeamOutlined,
} from "@ant-design/icons";
const { SubMenu } = Menu;
const { Header, Content, Footer, Sider } = Layout;

function Dashboard(props) {
  const [collapsed, setCollapsed] = useState(false);
  const [mobile, setMobile] = useState(false);
  const [profile_refreshed] = useState(false);
  useEffect(() => {
    props.history.listen(() => {
      mobile && setCollapsed(true);
    });
  }, [mobile]);
  const refreshProfile = async () => {
    let new_profile = await Backend.fetchProfile(props.hasoffers_profile);
    props.authAction(new_profile, "SET_AUTH");
  };
  useEffect(() => {
    refreshProfile();
  }, [profile_refreshed]);

  const logout = () => {
    Firestore.onLogout(props.authAction);
  };

  const onCollapse = (collapsed, type) => {
    setCollapsed(collapsed);
  };
  return (
    <Layout id="dashboard-container">
      <Header
        className="site-layout-sub-header-background"
        style={{ padding: 0 }}
      >
        <div className="logo">
          <Link to="/">
            <img alt="tik-tunes-logo" className="tik-tunes-logo" src={Logo} />
          </Link>
          <Link style={{ float: "right" }} to="/">
            <LogoutOutlined
              style={{ fontSize: "16px", color: "white" }}
              onClick={logout}
            />
          </Link>
        </div>
      </Header>
      <Layout>
        <Sider
          breakpoint="lg"
          collapsedWidth="0"
          defaultCollapsed={true}
          collapsed={collapsed}
          onBreakpoint={(broken) => {
            setMobile(broken);
          }}
          onCollapse={(collapsed, type) => {
            onCollapse(collapsed, type);
          }}
        >
          <Menu
            defaultOpenKeys={["sub1"]}
            forceSubMenuRender={true}
            theme="dark"
            mode="inline"
          >
            <Menu.Item key="1">
              <Link to="/">
                <AreaChartOutlined /> Snapshot
              </Link>
            </Menu.Item>
            <SubMenu key="sub1" icon={<TeamOutlined />} title="Affiliates">
              <Menu.Item key="/affiliates/manage">
                <Link to="/affiliates/manage">
                  <EditOutlined /> Manage
                </Link>
              </Menu.Item>
              <Menu.Item key="/affiliates/pending">
                <Link to="/affiliates/pending">
                  <UserAddOutlined /> Pending
                </Link>
              </Menu.Item>
            </SubMenu>
            <Menu.Item key="2">
              <Link
                to={{
                  pathname: "/offers",
                  fromLink: true,
                }}
              >
                <UserOutlined /> Offers
              </Link>
            </Menu.Item>
            <Menu.Item key="3">
              <Link to="/reports">
                <UserOutlined /> Reports
              </Link>
            </Menu.Item>
            <Menu.Item key="4">
              <Link to="/profile">
                <UserOutlined /> Profile
              </Link>
            </Menu.Item>
          </Menu>
        </Sider>
        <Layout>
          <Content>
            <Breadcrumb style={{ margin: "1rem" }}>
              <Breadcrumb.Item className="breadcrumb">
                <HomeFilled style={{ fontSize: "16px" }} />{" "}
                <Link to={`${props.location.pathname}`}>
                  {props.location.pathname === "/"
                    ? "/Snapshot"
                    : props.location.pathname.substring(1)}
                </Link>
              </Breadcrumb.Item>
            </Breadcrumb>
            <div className="site-layout-background">
              {mobile ? collapsed && props.children : props.children}
            </div>
          </Content>
          <Footer style={{ textAlign: "center" }}>
            TikTunes ©{moment().format("YYYY")}
          </Footer>
        </Layout>
      </Layout>
    </Layout>
  );
}
const mapStateToProps = (state) => ({
  hasoffers_profile: state.hasoffers_profile,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ authAction }, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Dashboard)
);
