import React, { useState, useEffect } from "react";
import moment from "moment";
import HasOffersAPI from "../../services/HasOffersAPI";
import { PageHeader, Menu, List, Dropdown, Row, Button } from "antd";
import { formatCurrency } from "../../util";
import { reportToSentry } from "../../util";
import { CaretUpOutlined, CaretDownOutlined } from "@ant-design/icons";
import { ExclamationOutlined } from "@ant-design/icons";
import Dates from "../DateRange";
const Today = moment().format("YYYY-MM-DD");

const StatCard = ({ title, data }) => (
  <List.Item>
    <List.Item.Meta title={title} />
    <div className="list_amount">
      {data && data === "null" ? (
        <ExclamationOutlined style={{ color: "#800121" }} />
      ) : (
        `${formatCurrency(data)}`
      )}
    </div>
  </List.Item>
);
const TopAffiliates = ({ auth }) => {
  const Report = HasOffersAPI.report;
  const [affiliates, setAffiliates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dropdownActive, setDropdownActive] = useState(false);
  const [dropdownSelected, setDropdownSelected] = useState(Dates[0].value);
  const fetchStats = async (query) => {
    return new Promise((resolve, reject) => {
      Report.getStats(query, true)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  };
  const grabStats = async (query, dates, filter) => {
    try {
      const api = await fetchStats({
        [`fields`]: ["Stat.payout", "Affiliate.id", "Affiliate.company"],
        [`filters[Stat.affiliate_manager_id][conditional]`]: "EQUAL_TO",
        [`filters[Stat.affiliate_manager_id][values]`]: 72,
        [`filters[Stat.date][conditional]`]: "BETWEEN",
        [`sort[Stat.payout]`]: "desc",
        totals: 1,
        limit: 5,
        ...query,
      });
      let data = api.data;
      setAffiliates(data);
      setLoading(false);
    } catch (error) {
      reportToSentry([
        "performance_chart",
        `Error fetching account totals data`,
        "HasOffersAPI",
      ]);
      return "error";
    }
  };
  useEffect(() => {
    const stats = async () => {
      await grabStats({
        [`filters[Stat.date][values][]`]: [Today, Today],
      });
    };
    stats();
  }, []);
  const onClick = async ({ item }) => {
    setDropdownSelected(item.props.dates.value);
    setLoading(true);
    const { start_date, end_date } = item.props.dates.current;
    await grabStats({
      [`filters[Stat.date][values][]`]: [start_date, end_date],
    });
  };
  const menu = (
    <Menu onClick={onClick}>
      {Dates.map((date) => {
        return (
          <Menu.Item dates={date} key={date.value}>
            {date.value}
          </Menu.Item>
        );
      })}
    </Menu>
  );
  return (
    <PageHeader
      className="main_page_header"
      style={{ width: "100%" }}
      title={`Top Affiliates`}
      extra={[
        <Dropdown
          key="1"
          placement="bottomRight"
          onVisibleChange={(visible) => setDropdownActive(visible)}
          overlay={menu}
          trigger={["click"]}
        >
          <Button>
            {dropdownSelected}
            {dropdownActive ? <CaretUpOutlined /> : <CaretDownOutlined />}
          </Button>
        </Dropdown>,
      ]}
    >
      <Row>
        <List
          loading={loading}
          className="account_total_list"
          size="small"
          style={{ width: "100%" }}
          bordered={false}
          dataSource={affiliates}
          renderItem={(item) => (
            <StatCard title={item.Affiliate.company} data={item.Stat.payout} />
          )}
        />
      </Row>
    </PageHeader>
  );
};
export default TopAffiliates;
