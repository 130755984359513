import React, { useState } from "react";
import * as Firestore from "../../services/Firebase";

import { Form, Input, Button, Checkbox } from "antd";
import HasOffersAPI from "../../services/HasOffersAPI";
import Backend from "../../services/Backend";
import { authAction } from "../../state/actions/AuthAction";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as Sentry from "@sentry/browser";

function Login({ authAction }) {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    setError(null);
    setLoading(true);
    try {
      let response = await HasOffersAPI.loginUser({
        ...values,
        type: "employee",
      });
      authAction(response, "SET_AUTH_HASOFFERS");
      let registerWithServer = await Backend.registerLoginWithServer(response);
      console.log("registerWithServer", registerWithServer);
      await Firestore.signInWithCustomToken(registerWithServer.firebaseToken);
      authAction(registerWithServer, "SET_AUTH");
    } catch (error) {
      Sentry.captureException(error);
      setLoading(false);
    }
  };

  return (
    <div className="form-container">
      <Form
        className="main-form "
        {...layout}
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
      >
        <h2 className="form-header-message">Welcome back!</h2>
        <Form.Item
          label="Email"
          name="email"
          rules={[{ required: true, message: "Please input your email!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: "Please input yout password!" }]}
        >
          <Input.Password />
        </Form.Item>
        {error && <Form.Item className="login-error">{error}</Form.Item>}
        <Form.Item {...tailLayout} name="remember" valuePropName="checked">
          <Checkbox>Remember me</Checkbox>
        </Form.Item>
        <Form.Item {...tailLayout}>
          <Button loading={loading} type="primary" htmlType="submit">
            Login
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

const mapStateToProps = (state) => ({ auth: state.auth });
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ authAction }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Login);

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};
const tailLayout = {
  wrapperCol: { offset: 0, span: 24 },
};
