import moment from "moment";

export const hour_am_pm = (data) => {
  let labels = [];
  data.map((stat) => {
    let { hour } = stat;
    if (hour < 12) {
      hour != 0 ? labels.push(`${hour}AM`) : labels.push(`12AM`);
    } else if (hour >= 12) {
      hour != 12 ? labels.push(`${hour - 12}PM`) : labels.push(`${hour}PM`);
    }
  });
  return labels;
};
export const date_label = (data) => {
  let labels = [];
  data.map((stat) => {
    let { date } = stat;
    data.length <= 7
      ? labels.push(moment(date).format("ddd"))
      : labels.push(moment(date).format("DD"));
  });
  return labels;
};
export const chart_data = (data) => {
  let chart_data = [];
  data.map((stat) => {
    let { payout } = stat.Stat;
    chart_data.push(parseInt(Math.round(payout)));
  });
  return chart_data;
};

export const dateRangeLabel = (dates) => {
  let label = moment(dates.start_date).calendar(null, {
    lastDay: "[Yesterday]",
    sameDay: "[Today]",
    nextDay: "[Tomorrow]",
    lastWeek: function (now) {
      if (this.isBefore(dates.end_date)) {
        return `[${moment(dates.start_date).format("MMM Do")} - ${moment(
          dates.end_date
        ).format("MMM Do")}]`;
      } else {
        return "dddd";
      }
    },
    nextWeek: "dddd",
    sameElse: `[${moment(dates.start_date).format("MMM Do")} - ${moment(
      dates.end_date
    ).format("MMM Do")}]`,
  });
  return label;
};

export const tooltip_afterTitle = (tooltipItem, data) => {
  let dropdownSelected =
    data.datasets[tooltipItem[0].datasetIndex].dropdownSelected;
  let actual_month = data.datasets[tooltipItem[0].datasetIndex].actual_month;
  let month = moment(actual_month).format("MM");
  let yr = moment(actual_month).format("YYYY");
  let newDate = new Date(`${month}/${tooltipItem[0].xLabel}/${yr}`);
  let month_label = moment(newDate).format("MMMM Do");
  let tool = tooltipItem[0];
  let filter =
    (data.datasets[0].label === "Yesterday") |
    (data.datasets[0].label === "Today")
      ? "hours"
      : "days";

  let label =
    filter === "hours"
      ? tool.index < 12
        ? tool.xLabel.includes("AM")
          ? tool.xLabel
          : `${tool.xLabel}AM`
        : tool.xLabel.includes("PM")
        ? tool.xLabel
        : `${tool.xLabel}PM`
      : (dropdownSelected === "Last 7 days") |
        (dropdownSelected === "Previous week(Sun - Sat)")
      ? tool.xLabel
      : month_label;
  return label || "";
};

export const chart_colors = [
  "rgb(181,223,139)",
  "rgb(255,132,130)",
  "rgb(245,189,99)",
  "rgb(120,192,209)",
  "rgb(240,106,58)",
  "rgb(232, 156, 242)",
  "rgb(92,144,198)",
  "rgb(144,111,169)",
  "rgb(249,217,119)",
  "rgb(124,191,183)",
  "rgb(218,154,190)",
  "rgb(203,133,176)",
  "rgb(127,140,148)",
  "rgb(215,168,168)",
  "rgb(230,27,35)",
];
